import type { App } from 'vue'
import * as Sentry from '@sentry/vue'
import { VERSION } from '@wastex/common'

export function setupSentry(app: App, environment: string) {
  Sentry.init({
    app,
    dsn: 'https://09a42355a2adea643bfa0d5231fadfa5@o4507893276475392.ingest.de.sentry.io/4507893278703696',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [
      'https://graphql-engine-xtq7ravppq-ew.a.run.app/v1/graphql',
      'https://graphql-engine-xmq7eperwq-ew.a.run.app/v1/graphql',
      'https://node-nest-api-xtq7ravppq-ew.a.run.app/graphql',
      'https://node-nest-api-xmq7eperwq-ew.a.run.app/graphql',
      'https://staging.wastex.tech',
      'https://app.wastex.tech',
    ],
    environment,
    release: VERSION,
    tracesSampleRate: environment === 'production' ? 0.1 : 1.0,
    replaysSessionSampleRate: environment === 'production' ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
  })
}
